<template>
  <div class="ele-body">
    <el-card shadow="never">
      <!-- 搜索表单 -->
      <el-form :model="table.where" class="ele-form-search d-flex" @keyup.enter.native="$refs.table.reload()"
        @submit.native.prevent>
        <el-form-item label="姓名:" label-width="44px" class="w-200">
          <el-input v-model="table.where.name" placeholder="请输入姓名" clearable />
        </el-form-item>
        <el-form-item label="车牌号:" label-width="60px" class="w-200">
          <el-input v-model="table.where.car_number" placeholder="请输入车牌号" clearable />
        </el-form-item>
        <el-form-item label="手机号:" label-width="62px" class="w-200">
          <el-input v-model="table.where.phone" placeholder="请输入手机号" clearable />
        </el-form-item>
        <el-form-item label="省:" label-width="35px" class="w-170">
          <el-select v-model="table.where.pid" @change="handleChangeProv(table.where.pid)" placeholder='请选择省' clearable>
            <el-option v-for="option in provArr" :value="option.pid" :key="option.pid" :label="option.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="市:" label-width="35px" class="w-150">
          <el-select v-model="table.where.cid" @change="handleChangeCity(table.where.cid), $forceUpdate()"
            placeholder='请选择市' clearable>
            <el-option v-for="option in cityArr" :value="option.cid" :key="option.cid" :label="option.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="县/区:" label-width="62px" class="w-200 mr-20">
          <el-select v-model="table.where.aid" placeholder='请选择县/区' @change="$forceUpdate()" clearable>
            <el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
              :label="option.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search"
            class="ele-btn-icon">搜素</el-button>
          <el-button @click="(table.where = { m_type: 1 }) && $refs.table.reload()">重置</el-button>
          <!--          <el-button icon="el-icon-download" type="primary" @click="exportExcel" v-if="permission.includes('sys:order:export')">导出</el-button>-->
          <download style="margin-left: 0" class="ele-btn-icon ml-20" label="导出" url="driver/exportExcelForBlackNew"
            v-if="permission.includes('sys:driver:export')" :params="exportOrderParams()" />
        </el-form-item>
      </el-form>
      <!-- 数据表格 -->
      <ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)" highlight-current-row
        :stripe=true>
        <template slot-scope="{index}">
          <el-table-column type="selection" width="45" align="center" fixed="left" />
          <el-table-column type="index" :index="index" label="编号" width="60" align="center" fixed="left"
            show-overflow-tooltip />
          <el-table-column prop="true_name" label="商户姓名" show-overflow-tooltip min-width="120" />
          <el-table-column prop="phone" label="电话" show-overflow-tooltip min-width="120" />
          <el-table-column prop="driver_area" label="所属区域" show-overflow-tooltip min-width="220" />
          <el-table-column prop="car_number" label="车牌号" show-overflow-tooltip min-width="110" />
          <el-table-column prop="credit" label="评分" show-overflow-tooltip min-width="120" />
          <el-table-column prop="driver_type" label="司机类型" show-overflow-tooltip>
            <template slot-scope="{row}">{{ row.driver_type == 1 ? '租车' : (row.driver_type == 2 ? '车主' : '/')
            }}</template>
          </el-table-column>
          <el-table-column prop="car_name" label="车辆类型" show-overflow-tooltip min-width="120" />
          <el-table-column prop="car_color" label="车辆颜色" show-overflow-tooltip min-width="120" />
          <!--sortable="custom"-->
          <el-table-column label="注册时间" show-overflow-tooltip min-width="160">
            <template slot-scope="{row}">{{ row.create_time * 1000 | toDateString }}</template>
          </el-table-column>
          <el-table-column label="上次登录时间" show-overflow-tooltip min-width="160">
            <template slot-scope="{row}">{{ row.active_time * 1000 | toDateString }}</template>
          </el-table-column>
          <el-table-column label="拉黑时间" show-overflow-tooltip min-width="160">
            <template slot-scope="{row}">{{ row.black_time * 1000 | toDateString }}</template>
          </el-table-column>
          <el-table-column prop="black_data" label="拉黑原因" show-overflow-tooltip min-width="220" />

          <el-table-column label="操作" width="200px" align="center" :resizable="false" fixed="right">
            <template slot-scope="{row}">
              <el-link @click="handlecheck(row)" icon="el-icon-view" type="primary" :underline="false"
                v-if="permission.includes('sys:blacklist:edit')">查看</el-link>
              <el-link @click="huifu(row)" slot="reference" icon="el-icon-refresh-left" type="primary" :underline="false"
                v-if="permission.includes('sys:blacklist:huifu')">恢复</el-link>
              <!-- <el-link @click="remove(row)" slot="reference" icon="el-icon-delete" type="danger" :underline="false" v-if="permission.includes('sys:blacklist:delete')">删除</el-link> -->
              <!--              <el-popconfirm title="确定要恢复此司机吗？" @confirm="huifu(row)" class="ele-action">-->
              <!--                <el-link slot="reference" icon="el-icon-success" type="primary" :underline="false" v-if="permission.includes('sys:blacklist:huifu')">恢复</el-link>-->
              <!--              </el-popconfirm>-->
              <!--              <el-popconfirm title="确定要删除此司机吗？" @confirm="remove(row)" class="ele-action">-->
              <!--                <el-link slot="reference" icon="el-icon-delete" type="danger" :underline="false" v-if="permission.includes('sys:blacklist:delete')">删除</el-link>-->
              <!--              </el-popconfirm>-->

            </template>
          </el-table-column>
        </template>
      </ele-data-table>
    </el-card>
    <!-- 查看司机详情 -->
    <el-dialog v-dialogDrag title="查看司机详情" :visible.sync="dialogTableVisibleCheck" width="900px" :destroy-on-close="true"
      :lock-scroll="false">
      <el-card shadow="never">
        <el-form :model="editForm" label-width="170px">
          <div style="display: flex;">
            <div style="border-right: 1px solid #C6C6C6;padding: 0 30px;">
              <el-form-item label="今日取消订单量:" prop="money">
                {{ checkForm.today_cancel }}
              </el-form-item>
              <el-form-item label="本月累计取消订单量:" prop="invite_money">
                {{ checkForm.thisMon_cancel }}
              </el-form-item>
              <el-form-item label="本月累计接单量：" prop="total_time">
                {{ checkForm.month_order_total }}
              </el-form-item>
              <el-form-item label="本月总收益：" prop="money">
                {{ checkForm.month_order_price }}
              </el-form-item>
              <el-form-item label="本月订单收益：" prop="invite_money">
                {{ checkForm.month_order_earning }}
              </el-form-item>
              <el-form-item label="本月红包收益：" prop="total_time">
                {{ checkForm.month_order_fee }}
              </el-form-item>
              <el-form-item label="本月推广收益：" prop="total_time">
                {{ checkForm.month_order_promote }}
              </el-form-item>
              <el-form-item label="司机姓名：" prop="true_name">
                {{ checkForm.name }}
              </el-form-item>
              <el-form-item label="头像：">
                <el-image style="width:100px;height:100px;margin-right:10px" :src="checkForm.true_head" fit="cover"
                  :preview-src-list="[checkForm.true_head]"></el-image>

                <!--								<img :src="checkForm.true_head" width="70"   height="70" />-->
              </el-form-item>
              <el-form-item label="是否载客" prop="phone">
                {{ checkForm.is_carry }}
              </el-form-item>
              <el-form-item label="手机号：" prop="phone">
                {{ checkForm.phone }}
              </el-form-item>
              <el-form-item label="所属区域：">
                {{ checkForm.driver_area }}
              </el-form-item>
              <el-form-item label="累计接单收益：" prop="money">
                {{ checkForm.money }}
              </el-form-item>
              <el-form-item label="诚信度：" prop="invite_money">
                {{ checkForm.credit }}
              </el-form-item>
              <el-form-item label="累计在线时长：" prop="total_time">
                {{ checkForm.total_time }}分钟
              </el-form-item>
              <el-form-item label="最近的登录时间：" prop="money">
                {{ checkForm.active_time * 1000 | toDateString }}
              </el-form-item>
              <el-form-item label="注册时间：" prop="invite_money">
                {{ checkForm.create_time * 1000 | toDateString }}
              </el-form-item>
              <el-form-item label="性别：" prop="total_time">
                {{ checkForm.sex == 0 ? '男' : '女' }}
              </el-form-item>
              <el-form-item label="司机类型：">
                {{ checkForm.driver_type == 1 ? '租车' : (checkForm.driver_type == 2 ? '车主' : '/') }}
              </el-form-item>
            </div>
            <div>
              <el-form-item label="真实姓名：" prop="total_time">
                {{ checkForm.true_name }}
              </el-form-item>
              <el-form-item label="身份证号：" prop="idcard">
                {{ checkForm.idcard }}
              </el-form-item>
              <el-form-item label="支付宝账号：" prop="idcard">
                {{ checkForm.ali_number }}
              </el-form-item>
              <el-form-item label="车牌号：" prop="car_number">
                {{ checkForm.car_number }}
              </el-form-item>
              <el-form-item label="手持身份证照片：">
                <el-image style="width:100px;height:100px;margin-right:10px" :src="checkForm.idcard_hand" fit="cover"
                  :preview-src-list="[checkForm.idcard_hand]"></el-image>
                <!--								<img :src="checkForm.idcard_hand" width="70"   height="70" />-->
              </el-form-item>
              <el-form-item label="身份证正面照：">
                <el-image style="width:100px;height:100px;margin-right:10px" :src="checkForm.idcard_front" fit="cover"
                  :preview-src-list="[checkForm.idcard_front]"></el-image>
                <!--								<img :src="checkForm.idcard_front" width="70"   height="70" />-->
              </el-form-item>
              <el-form-item label="身份证反面照：">
                <el-image style="width:100px;height:100px;margin-right:10px" :src="checkForm.idcard_back" fit="cover"
                  :preview-src-list="[checkForm.idcard_back]"></el-image>

                <!--								<img :src="checkForm.idcard_back" width="70"   height="70" />-->
              </el-form-item>
              <el-form-item label="驾驶证正面照：">
                <el-image style="width:100px;height:100px;margin-right:10px" :src="checkForm.jiacard_front" fit="cover"
                  :preview-src-list="[checkForm.jiacard_front]"></el-image>

                <!--								<img :src="checkForm.jiacard_front" width="70"   height="70" />-->
              </el-form-item>
              <el-form-item label="行驶证正面照：">
                <el-image style="width:100px;height:100px;margin-right:10px" :src="checkForm.xingcard_front" fit="cover"
                  :preview-src-list="[checkForm.xingcard_front]"></el-image>

                <!--								<img :src="checkForm.xingcard_front" width="70"   height="70" />-->
              </el-form-item>
              <el-form-item label="车辆正面照：">
                <el-image style="width:100px;height:100px;margin-right:10px" :src="checkForm.car_front" fit="cover"
                  :preview-src-list="[checkForm.car_front]"></el-image>

                <!--								<img :src="checkForm.car_front" width="70"   height="70" />-->
              </el-form-item>
              <el-form-item label="出租车运营资格证：">
                <el-image style="width:100px;height:100px;margin-right:10px" :src="checkForm.taxi_operation" fit="cover"
                  :preview-src-list="[checkForm.taxi_operation]"></el-image>

                <!--								<img :src="checkForm.taxi_operation" width="70"   height="70" />-->
              </el-form-item>
              <el-form-item label="举报司机信息：" prop="report_data"> {{ checkForm.report_data }}</el-form-item>
              <el-form-item label="举报原因：" prop="report_reason">
                {{ checkForm.report_reason }}
              </el-form-item>
              <el-form-item label="附加图片：" prop="report_image"> 


                <span v-if="checkForm.report_image != '/'">
                  <span v-for=" (item, index) in checkForm.report_image" :key="index">
                    <el-image style="width:90px;height:90px;margin-right:5px" :src="item" fit="cover"
                      :preview-src-list="[item]"></el-image>
                  </span>

                </span>
                <span v-else>
                  暂无图片
                </span>

              </el-form-item>


            </div>
          </div>
        </el-form>
      </el-card>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import download from '@/components/Download/index'
export default {
  name: "SysUser",
  components: { download },
  data() {
    return {
      table: { url: '/driver/index_black', where: { m_type: 1 } },  // 表格配置
      choose: [],  // 表格选中数据
      showEdit: false,  // 是否显示表单弹窗
      editForm: {},  // 表单数据
      editRules: {  // 表单验证规则
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: '请输入正确的手机号',
            trigger: 'blur',
          }
        ],
        name: [
          { required: true, message: '请输入昵称', trigger: 'blur' }
        ],

      },
      provArr: [],
      cityArr: [],
      districtArr: [],
      checkForm: [],
      dialogTableVisibleCheck: false,
    }
  },
  created() {

    this.$http.get('/common/province_list').then(res => {
      let data = JSON.parse(res.data)
      this.provArr = data
    })

  },
  computed: {
    ...mapGetters(["permission"]),
  },

  mounted() {
  },
  methods: {
    // 导出数据参数
    exportOrderParams() {
      const query = this.table.where
      // const params = this.params
      const selectionIds = this.choose.map(item => {
        return item.id
      })

      return { ids: selectionIds, ...query }
    },
    /**
     *选择省
     **/
    handleChangeProv(e) {
      /** 获取被选省份的pid**/
      let pid = ''
      this.provArr.forEach(function (item) {
        if (item.pid == e) {
          pid = item.pid
        }
      })
      /** 根据被选省份的pid获取省市下面的市**/
      this.$http.post('/common/city_list', { pid: pid }).then(res => {
        let data = JSON.parse(res.data)
        this.cityArr = data
        /** 选择省份清空市县**/
        this.table.where.cid = ''
        this.table.where.aid = ''



      })
    },
    /**
     *选择市
     **/
    handleChangeCity(e) {
      if (e) {
        /** 获取被选市的cid**/
        let cid = ''
        this.cityArr.forEach(function (item) {
          if (item.cid == e) {
            cid = item.cid
          }
        })
        /** 根据被选市的cid获取市下面的县**/
        this.$http.post('/common/area_list', { cid: cid }).then(res => {
          let data = JSON.parse(res.data)
          this.districtArr = data
          /** 选择省份清空县**/
          this.table.where.aid = ''
        })
      } else {
        this.table.where.pid = ''
        this.table.where.aid = ''
      }
    },


    /**
     *查看用户信息
     **/
    handlecheck(row) {
      this.dialogTableVisibleCheck = true
      this.checkForm = row;
    },

    /**
     * 显示编辑
     */
    edit(row) {
      this.$http.get('/driver/info?id=' + row.id).then(res => {
        if (res.data.code === 0) {
          this.editForm = res.data.data;
          this.showEdit = true;
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(e => {
        this.$message.error(e.message);
      });
    },
    /**
     * 保存编辑
     */
    save() {
      this.$refs['editForm'].validate((valid) => {
        if (valid) {
          const loading = this.$loading({ lock: true });
          this.$http.post('/driver/edit', this.editForm).then(res => {
            loading.close();
            if (res.data.code === 0) {
              this.showEdit = false;
              this.$message({ type: 'success', message: res.data.msg });
              this.$refs.table.reload();
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            loading.close();
            this.$message.error(e.message);
          });
        } else {
          return false;
        }
      });
    },
    /**
     * 刪除(批量刪除)
     */
    remove(row) {
      //console.log(row)
      if (!row) {  // 批量删除
        if (this.choose.length === 0) return this.$message.error('请至少选择一条数据');
        let ids = this.choose.map(d => d.id);
        this.$confirm('确定要删除选中的司机吗?', '提示', { type: 'warning' }).then(() => {
          const loading = this.$loading({ lock: true });
          this.$http.post('/driver/delete', { id: ids }).then(res => {
            loading.close();
            if (res.data.code === 0) {
              this.$message({ type: 'success', message: res.data.msg });
              this.$refs.table.reload();
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            loading.close();
            this.$message.error(e.message);
          });
        }).catch(() => 0);
      } else {  // 单个删除
        this.$confirm('确定要删除选中的司机吗?', '提示', { type: 'warning' }).then(() => {
          const loading = this.$loading({ lock: true });
          this.$http.post('/driver/delete', { id: row.id }).then(res => {
            loading.close();
            if (res.data.code === 0) {
              this.$message({ type: 'success', message: res.data.msg });
              this.$refs.table.reload();
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            loading.close();
            this.$message.error(e.message);
          });
        })
      }
    },

    //  恢复方法
    huifu(row) {
      this.$confirm('确认恢复该司机？', '提示', { type: 'warning' }).then(() => {
        const loading = this.$loading({ lock: true });
        this.$http.post('/driver/huifu', { id: row.id }).then(res => {
          loading.close();
          if (res.data.code === 0) {
            this.$message({ type: 'success', message: res.data.msg });
            this.$refs.table.reload();
          } else {
            this.$message.error(res.data.msg);
          }
        }).catch(e => {
          loading.close();
          this.$message.error(e.message);
        });
      })
    },


    /* 导出数据Excel */
    exportExcel() {
      let info = JSON.parse(JSON.stringify(this.table.where));
      //console.log('user data export')
      this.$http
        .get("/driver/exportExcelForBlack", info)
        .then((res) => {
          let data = res.data;
          if (data.code == 0) {
            // 下载文件
            window.location.href = data.data;
            this.$message({
              type: "success",
              message: "导出成功",
            });
          } else {
            this.$message.error("导出失败");
          }
        })
    },

  }
}
</script>

<style scoped>
.ele-block .el-upload-dragger {
  width: 100%;
}

/deep/.el-dialog {
  margin-top: 60px !important;
}
</style>